<!--
 * @Description: 
 * @Version: 2.0
 * @Author: 王有毒
 * @Date: 2023-03-24 11:44:37
 * @LastEditors: 王有毒
 * @LastEditTime: 2023-08-11 15:37:52
 * @FilePath: \edub_tob_pc\src\components\cutdown.vue
-->
<template>
  <div class="cutDown">
    <div class="time">{{ day < 0 ? 0 : day }}</div>
        <span>天</span>
        <div class="time">{{ hour < 0 ? 0 : hour }}</div>
            <span>时</span>
            <div class="time">{{ minute < 0 ? 0 : minute }}</div>
                <span>分</span>
                <div class="time">{{ second < 0 ? 0 : second }}</div>
                    <span>秒</span>
                </div>
</template>

<script>
export default {
  data() {
    return {
      day: 0,
      hour: 0,
      minute: 0,
      second: 0,
      nowTime: +new Date(),
      cutDown: null
    }
  },
  props: ['startTime'],
  created() {
    console.log(this.startTime)
    // 创建定时器
    this.cutDown = setInterval(() => {
      const t = +new Date(this.startTime) - +new Date()
      this.day = Math.floor(t / 86400000)
      this.hour = Math.floor((t / 3600000) % 24)
      this.minute = Math.floor((t / 60000) % 60)
      this.second = Math.floor((t / 1000) % 60)
    }, 1000)
  },
  destroyed() {
    clearInterval(this.cutDown)
    this.cutDown = null
  }
}
</script>

<style lang="less" scoped>
.cutDown {
  display: flex;
  align-items: center;

  span {
    margin: 0 10px;
  }

  .time {
    width: 25px;
    height: 24px;
    background: #ff5e51;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }
}
</style>
